<template>
    <div class="ma-3">
        <v-card flat :loading="loading">
            <div class="ma-5 mb-2">
                <v-card-title>{{ pageTitle }}</v-card-title>
                <v-card-subtitle>{{ pageSubtitle }}</v-card-subtitle>
                <v-card-text v-if="customer != null">
                    <v-form ref="form">
                        <v-row>
                            <v-col cols="6">
                                <div v-if="isChild">
                                    <div class="subheading grey--text">
                                        Parent Information
                                    </div>

                                    <div class="parentInfo">
                                        <div>{{ parentError }}</div>

                                        <finder append-icon="mdi-magnify" @find="addFoundCustomer" search-category="customers" 
                                            label="Find customer by phone, email or name" class="ma-2 mb-0" :show-new-customer-button="false"></finder>

                                        <v-card-title class="title">
                                            {{ customer.parent.fullName }}

                                            <v-spacer></v-spacer>

                                            <v-btn icon :to="`/customers/${customer.parent.id}`" target="_blank">
                                                <v-icon>mdi-open-in-new</v-icon>
                                            </v-btn>
                                        </v-card-title>
                                        <v-card-subtitle>{{ formatPhoneNum( customer.parent.phone ) }}</v-card-subtitle>
                                    </div>
                                </div>

                                <div class="subheading grey--text">
                                    Customer Information
                                </div>

                                <v-row no-gutters>
                                    <v-col cols="4">
                                        <v-select v-model="customer.customerTypeId" :items="customerTypes" item-text="name" item-value="id" :rules="[ rules.required ]"></v-select>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="6">
                                        <v-text-field v-model="customer.firstName" label="First Name" :rules="[ rules.required ]"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <v-text-field v-model="customer.lastName" label="Last Name" :rules="[ rules.required ]"></v-text-field>
                                    </v-col>
                                </v-row>

                                <v-row>
                                    <v-col cols="6">
                                        <div class="subheading grey--text">
                                            General Information
                                        </div>

                                        <v-text-field v-model="customer.phone" label="Phone" v-mask="'(###) ###-####'" :rules="[ rules.phoneRequired, rules.isPhone ]" :disabled="isChild"></v-text-field>
                                        <v-text-field v-model="customer.email" label="Email" :rules="[ rules.isEmail ]" :disabled="isChild"></v-text-field>
                                        <v-text-field v-model="customer.birthdate" label="Birthdate" v-mask="'##/##/####'" :rules="[ rules.isDate ]" hint="mm/dd/yyyy"></v-text-field>
                                        <v-select v-model="customer.gender" label="Gender" :items="[{text:'Male', value:'m'},{text:'Female',value:'f'},{text:'Neutral',value:'n'}]"></v-select>
                                        <v-text-field v-model="customer.otherId" label="Other ID" :disabled="isChild"></v-text-field>
                                    </v-col>
                                    <v-col cols="6">
                                        <div class="subheading grey--text">
                                            Address
                                        </div>

                                        <v-text-field v-model="customer.addressLine1" label="Address Line 1" :disabled="isChild"></v-text-field>
                                        <v-text-field v-model="customer.addressLine2" label="Address Line 2" :disabled="isChild"></v-text-field>

                                        <v-row>
                                            <v-col cols="5">
                                                <v-text-field v-model="customer.addressCity" label="City" :disabled="isChild"></v-text-field>
                                            </v-col>
                                            <v-col cols="3">
                                                <v-text-field v-model="customer.addressState" v-mask="'AA'" label="State" :disabled="isChild"></v-text-field>
                                            </v-col>
                                            <v-col cols="4">
                                                <v-text-field v-model="customer.addressZipcode" v-mask="'#####'" label="Zipcode" :disabled="isChild"></v-text-field>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                            </v-col>

                            <v-col cols="6">
                                <div class="subheading grey--text mb-3">
                                    Measurements
                                </div>

                                <v-expansion-panels>
                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            Rental measurements
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content eager>
                                            <customer-rental-measurements ref="customerRentalMeasurements" :measurements="customer.rentalMeasurements"
                                                :measures-required="false" @error="savingRentalMeasurementsError" />
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>

                                    <v-expansion-panel>
                                        <v-expansion-panel-header>
                                            DTM measurements
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content eager>
                                            <customer-measurements ref="customerMeasurements" :measurements="customer.measurements"
                                                :measures-required="false" @error="savingMeasurementsError" />
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>

                                    <v-expansion-panel v-if="!isChild">
                                        <v-expansion-panel-header>
                                            Dependents
                                        </v-expansion-panel-header>
                                        <v-expansion-panel-content eager>
                                            <div class="text-right">
                                                <v-btn depressed small color="grey darken-4" class="white--text" @click="dependentDialog = true">Add dependent</v-btn>
                                            </div>
                                            <v-data-table :headers="dependentHeaders" :items="customer.dependents">
                                                <template v-slot:item.edit="{ item }">
                                                    <v-btn icon @click="editDependent(item)">
                                                        <v-icon>mdi-pencil</v-icon>
                                                    </v-btn>
                                                </template>
                                                <template v-slot:item.goto="{ item }">
                                                    <v-btn icon :to="`/customers/${item.id}`" target="_blank">
                                                        <v-icon>mdi-open-in-new</v-icon>
                                                    </v-btn>
                                                </template>
                                            </v-data-table>
                                        </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-col>
                        </v-row>
                    </v-form>

                    <v-row class="mt-5">
                        <v-col cols="12">
                            <div class="subheading grey--text mb-3">
                                Latest orders
                            </div>

                            <v-data-table :headers="ordersHeaders" :items="customer.orders" @click:row="toOrderDetails">
                                <template v-slot:item.wantDate="{ item }">
                                    {{ formatWantDate( item.wantDate ) }}
                                </template>
                                <template v-slot:item.total="{ item }">
                                    {{ formatMoney( item.total ) }}
                                </template>
                                <template v-slot:item.balance="{ item }">
                                    {{ formatMoney( item.balance ) }}
                                </template>
                                <template v-slot:item.status="{ item }">
                                    <span :class="`${orderStatusColor(item.status)}--text`">{{ item.status }}</span>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>

                    <div class="text-right mt-7">
                        <v-btn depressed color="grey darken-4" class="white--text" @click="save" :loading="saving">Save</v-btn>
                    </div>
                </v-card-text>
            </div>
        </v-card>

        <v-dialog v-model="dependentDialog" width="600" persistent>
            <v-card>
                <v-card-title>Configure dependent</v-card-title>
                <v-card-subtitle>Create or edit customer dependents</v-card-subtitle>
                <v-card-text>
                    <child-customer-information v-model="dependent" val-strength="" ref="childCustomer" :parent-search="false" />
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn text @click="closeDependentDialog">Close</v-btn>
                    <v-btn depressed color="grey darken-4" class="white--text" :loading="savingChild" @click="saveChild">Save</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import CustomerRentalMeasurements from '@/components/CustomerRentalMeasurements.vue';
import CustomerMeasurements from '@/components/CustomerMeasurements.vue';
import Finder from '@/components/Finder.vue';
import ChildCustomerInformation from '@/components/ChildCustomerInformation.vue';
import { mapActions } from 'vuex';
import { API } from '@/inc/api';
import { _st } from '@/softech';
import moment from 'moment';
import { mask } from 'vue-the-mask';

export default {
    props: {
        customerId: { type: [ Number, String ], default: null }
    },
    data() {
        return {
            customer            : null,
            customerTypes       : [],
            loading             : false,
            saving              : false,
            savingChild         : false,

            ordersHeaders       : [
                { text: 'ID', value: 'foreignId', sortable: true },
                { text: 'Pickup Date', value: 'wantDate', sortable: true },
                { text: 'Use Date', value: 'useDate', sortable: true },
                { text: 'Return Date', value: 'returnDate', sortable: true },
                { text: 'Status', value: 'status', sortable: true },
                { text: 'Total', value: 'total', sortable: true, align: 'right' },
                { text: 'Balance', value: 'balance', sortable: true, align: 'right' },
            ],

            dependentHeaders    : [
                { text: 'Full Name', value: 'fullName', sortable: true, width: '80%' },
                { text: 'Edit', value: 'edit', sortable: true, align: 'right', width: '10%' },
                { text: 'Open', value: 'goto', sortable: true, align: 'right', width: '10%' },
            ],

            rules               : {
                required        : v => !!v || 'required',
                isEmail         : v => ( _st.isNUE( v ) || _st.isEmail( v ) ) || 'must be a valid email address',
                isPhone         : v => ( _st.isEmpty( v ) || _st.isPhone( v ) ) || 'must be a valid phone number',
                isInteger       : v => _st.isInteger( v ) && v >= 0 || 'must be a positive integer value',
                isTime          : v => !_st.isNUE( v ) && v.split(':').length >= 2 || 'must be a valid time',
                isDate          : v => ( _st.isNUE( v ) || moment( v ).isValid() ) || 'must be a valid date',
                phoneRequired   : v => ( this.isChild || !!v ) || 'required',
            },

            rMeasurementsError  : '',
            measurementsError   : '',

            parentError         : '',
            dependent           : null,
            dependentDialog     : false,
        }
    },
    computed: {
        pageTitle() {
            if( _st.isNUE( this.customerId ) || this.customerId == 'new' ) {
                return 'Create new customer';
            }
            
            return 'Edit customer';
        },
        pageSubtitle() {
            if( _st.isNUE( this.customerId ) ) {
                return 'Create a new customer to be used within the LFA Rental App';
            }
            else {
                return 'Edit all the details of the customer';
            }
        },
        isChild() {
            if( this.customer == null )
                return false;

            return !_st.isNUE( this.customer.parentId );
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        ...mapActions({
            saveCustomer: 'customers/saveCustomer'
        }),
        async init() {
            try {
                this.cleanCustomerObject();
                this.loadObjects();

                if( _st.isNUE( this.customerId ) || this.customerId == 'new' ) {
                    return;
                }

                let api = new API();

                this.loading = true;
                let res = await api.get(`/admin/customer/${this.customerId}`);
                this.loading = false;

                if( !res.data.status ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return false;
                }

                if( _st.isNUE( res.data.data.id ) ) {
                    this.$router.push('/404');
                    return;
                }

                this.customer = res.data.data;
                this.customer.phone = _st.toPhoneNumber( this.customer.phone??'' );
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( 'ERROR: ' + error, 'error' );
            }
        },
        async loadObjects() {
            let api = new API();

            try {
                this.loading = true;
                let res = await api.get('/admin/customer-type');
                this.loading = false;

                if( !res.data.status ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return false;
                }

                this.customerTypes = res.data.data;
            }
            catch(error) {
                this.loading = false;
                this.mxShowMessage( 'ERROR: ' + error, 'error' );
            }
        },
        cleanCustomerObject() {
            this.customer = {
                id                  : 0,
                parentId            : 0,
                parent              : null,
                firstName           : '',
                lastName            : '',
                fullName            : '',
                customerTypeId      : 0,
                customerType        : '',
                birthdate           : null,
                phone               : '',
                email               : '',
                gender              : '',
                addressLine1        : '',
                addressLine2        : '',
                addressCity         : '',
                addressState        : '',
                addressZipcode      : '',
                dateCreated         : null,
                otherId             : '',
                reservations        : [],
                orders              : [],
                dependents          : [],
                measurements        : null,
                rentalMeasurements  : null
            }
        },
        formatWantDate(d) {
            return moment(d).format('yyyy-MM-DD @ hh:mm a')
        },
        savingRentalMeasurementsError(error) {
            this.rMeasurementsError = error;
        },
        savingMeasurementsError(error) {
            this.measurementsError = error;
        },
        async save() {
            if( !this.$refs.form.validate() | !this.$refs.customerRentalMeasurements.isValid() | !this.$refs.customerMeasurements.isValid() ) {
                this.mxShowMessage( 'Missing information', 'warning' );
                return;
            }

            // save the customer
            let api = new API();

            try {
                this.customer.rentalMeasurements = this.$refs.customerRentalMeasurements.rentalMeasurements;
                this.customer.measurements = this.$refs.customerMeasurements.newMeasurements;

                this.saving = true;
                let res = await api.post('/admin/customer', this.customer);
                this.saving = false;

                if( res.data.status !== true ) {
                    this.mxShowMessage( res.data.message, res.data.error ? 'error' : 'warning' );
                    return;
                }

                if( _st.isNUE( this.customerId ) || this.customerId == 'new' ) {
                    this.$router.push(`/customers/${res.data.data.id}`);
                }
                else {
                    this.customer = res.data.data;
                    this.customer.customerTypeId = this.customer.customerTypeId.toString();
                    this.customer.phone = _st.toPhoneNumber( this.customer.phone );
                }

                this.mxShowMessage('Customer saved', 'success');
            }
            catch(error) {
                this.saving = false;
                this.mxShowMessage(error, 'error');
            }
        },
        toOrderDetails(item) {
            this.$router.push(`/orders/${item.id}`);
        },
        addFoundCustomer(c) {
            if( !_st.isNUE( c.parentId ) ) {
                this.mxShowMessage( 'The parent cannot be a child customer.', 'error' );
                return;
            }

            this.customer.parent = c;
            this.parentError = '';
        },
        editDependent(c) {
            this.dependent = c;
            this.dependentDialog = true;
        },
        closeDependentDialog() {
            this.dependentDialog = false;
            this.dependent = null;
            this.$refs.childCustomer.clearForm();
        },
        async saveChild() {
            if( !this.$refs.childCustomer.isValid() )
                return;

            if( _st.isNUE( this.customerId ) || this.customerId == 'new' ) {
                if( _st.isNUE( this.dependent.internalId ) ) {
                    this.customer.dependents.push({
                        id          : this.dependent.id,
                        firstName   : this.dependent.firstName,
                        lastName    : this.dependent.lastName,
                        fullName    : `${this.dependent.firstName} ${this.dependent.lastName}`,
                        birthdate   : this.dependent.birthdate,
                        gender      : this.dependent.gender,
                        internalId  : this.customer.dependents.length + 1
                    });
                }
                else {
                    let ix = this.customer.dependents.findIndex(c => c.internalId == this.dependent.internalId);
                    this.customer.dependents[ix].id = this.dependent.id;
                    this.customer.dependents[ix].firstName = this.dependent.firstName;
                    this.customer.dependents[ix].lastName = this.dependent.lastName;
                    this.customer.dependents[ix].fullName = `${this.dependent.firstName} ${this.dependent.lastName}`;
                    this.customer.dependents[ix].birthdate = this.dependent.birthdate;
                    this.customer.dependents[ix].gender = this.dependent.gender;
                }

                this.closeDependentDialog();
                return;
            }

            let api = new API();

            try {
                this.savingChild = true;
                
                let action = 'post';
                let res = await api.post(`/admin/customer/child`, {
                    parentId: this.customer.id,
                    child: {
                        id: this.dependent.id,
                        firstName: this.dependent.firstName,
                        lastName: this.dependent.lastName,
                        birthdate: this.dependent.birthdate,
                        gender: this.dependent.gender
                    }
                });
                this.savingChild = false;
                

                if( !_st.isNUE( this.dependent.id ) ) 
                    action = 'put';

                if( res.data.status !== true ) {
                    this.mxShowMessage(res.data.message, res.data.error ? 'error' : 'warning');
                    return;
                }

                if( action == 'post' )
                    this.customer.dependents.push( res.data.data );
                else {
                    let ix = this.customer.dependents.findIndex(c => c.id == res.data.data.id);
                    this.customer.dependents[ix].id = res.data.data.id;
                    this.customer.dependents[ix].firstName = res.data.data.firstName;
                    this.customer.dependents[ix].lastName = res.data.data.lastName;
                    this.customer.dependents[ix].fullName = res.data.data.fullName;
                    this.customer.dependents[ix].birthdate = res.data.data.birthdate;
                    this.customer.dependents[ix].gender = res.data.data.gender;
                }
                
                this.dependentDialog = false;
                this.mxShowMessage('Dependent saved', 'success');
            }
            catch(error) {
                this.savingChild = false;
                this.mxShowMessage(error, 'error');
            }
        }
    },
    directives: { mask },
    components: {
        CustomerRentalMeasurements, CustomerMeasurements, Finder, ChildCustomerInformation
    }
}
</script>

<style lang="scss" scoped>
.parentInfo {
    background-color: #e7e7e7;
    border-radius: 8px;
    margin: 16px 0;
}
</style>