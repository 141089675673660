<template>
    <div>
        <v-form ref="form1" v-model="valid">
            <v-row>
                <v-col cols="4" class="pr-2">
                    <v-text-field v-model="heightFeet" label="Height - Feet" @keydown="intOnly($event)"
                        suffix="ft" :error-messages="heightFeetError"></v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                    <v-text-field v-model="heightInch" label="Height - Inches" @keydown="intOnly($event)"
                        suffix="in" :error-messages="heightInchError"></v-text-field>
                </v-col>
                <v-col cols="4" class="pl-2">
                    <v-text-field v-model="weight" label="Weight" suffix="lbs" :error-messages="weightError" @keydown="intOnly($event)"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="pr-2">
                    <v-select v-model="watchHand" :items="watchHandOptions" item-text="name" item-value="id"
                        label="Watch Hand"></v-select>
                </v-col>
                <v-col cols="4" class="px-2">
                    <v-text-field v-model="neck" :rules="neckRules" label="Neck" suffix="in" @keydown="numbersOnly($event)"
                        append-icon="mdi-video" @click:append="showVideo('neck')"></v-text-field>
                </v-col>
                <v-col cols="4" class="pl-2">
                    <v-text-field v-model="sleeveLeft" :rules="sleeveRules" label="Sleeve Length - Left" @keydown="numbersOnly($event)"
                        suffix="in" append-icon="mdi-video" @click:append="showVideo('sleeveLength')"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="pr-2">
                    <v-text-field v-model="sleeveRight" :rules="sleeveRules" label="Sleeve Length - Right" @keydown="numbersOnly($event)"
                        suffix="in" append-icon="mdi-video" @click:append="showVideo('sleeveLength')"></v-text-field>
                </v-col>
                <v-col col="4" class="px-2">
                    <v-text-field v-model="bicep" :rules="bicepRules" label="Biceps" suffix="in" @keydown="numbersOnly($event)"
                            append-icon="mdi-video" @click:append="showVideo('bicep')"></v-text-field>
                </v-col>
                <v-col cols="4" class="pl-2">
                    <v-text-field v-model="wrist" :rules="wristRules" label="Wrist" suffix="in" @keydown="numbersOnly($event)"
                        append-icon="mdi-video" @click:append="showVideo('wrist')"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="pr-2">
                    <v-text-field v-model="chest" :rules="chestRules" label="Chest" suffix="in" @keydown="numbersOnly($event)"
                        append-icon="mdi-video" @click:append="showVideo('chest')"></v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                    <v-text-field v-model="waist" :rules="waistRules" label="Waist" suffix="in" @keydown="numbersOnly($event)"
                        append-icon="mdi-video" @click:append="showVideo('waist')"></v-text-field>
                </v-col>
                <v-col cols="4" class="pl-2">
                    <v-text-field v-model="stomach" :rules="stomachRules" label="Stomach" @keydown="numbersOnly($event)"
                        suffix="in" append-icon="mdi-video" @click:append="showVideo('stomach')"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="pr-2">
                    <v-text-field v-model="hips" :rules="hipsRules" label="Hips" suffix="in" @keydown="numbersOnly($event)"
                        append-icon="mdi-video" @click:append="showVideo('hips')"></v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                    <v-text-field v-model="leg" :rules="legRules" label="Leg Length" suffix="in" @keydown="numbersOnly($event)"
                        append-icon="mdi-video" @click:append="showVideo('legLength')"></v-text-field>
                </v-col>
                <v-col cols="4" class="pl-2">
                    <v-text-field v-model="thigh" :rules="thighRules" label="Thigh" suffix="in" @keydown="numbersOnly($event)"
                        append-icon="mdi-video" @click:append="showVideo('thigh')"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="pr-2">
                    <v-text-field v-model="knee" :rules="kneeRules" label="Knee" suffix="in" @keydown="numbersOnly($event)"
                        append-icon="mdi-video" @click:append="showVideo('knee')"></v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                    <v-text-field v-model="calf" label="Calf" suffix="in" @keydown="numbersOnly($event)"></v-text-field>
                </v-col>
                <v-col cols="4" class="pl-2">
                    <v-text-field v-model="cuff" :rules="cuffRules" label="Cuff" suffix="in" @keydown="numbersOnly($event)"
                        append-icon="mdi-video" @click:append="showVideo('cuff')"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="pr-2">
                    <v-text-field v-model="crotch" :rules="crotchRules" label="Crotch" suffix="in" @keydown="numbersOnly($event)"
                        append-icon="mdi-video" @click:append="showVideo('crotch')"></v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                    <v-text-field v-model="shoulder" :rules="shoulderRules" label="Shoulders" @keydown="numbersOnly($event)"
                        suffix="in" append-icon="mdi-video" @click:append="showVideo('shoulder')"></v-text-field>
                </v-col>
                <v-col cols="4" class="pl-2">
                    <v-text-field v-model="coatSleeveLeft" :rules="coatSleeveRules" label="Coat Sleeve Length - Left"
                        suffix="in" @keydown="numbersOnly($event)" required></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="pr-2">
                    <v-text-field v-model="coatSleeveRight" :rules="coatSleeveRules" label="Coat Sleeve Length - Right"
                        suffix="in" @keydown="numbersOnly($event)"></v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                    <v-text-field v-model="jacketFront" :rules="jacketFrontRules" label="Jacket Length - Front" @keydown="numbersOnly($event)"
                        suffix="in" append-icon="mdi-video" @click:append="showVideo('jacketLength')"></v-text-field>
                </v-col>
                <v-col cols="4" class="pl-2">
                    <v-text-field v-model="jacketBack" :rules="jacketBackRules" label="Jacket Length - Back" @keydown="numbersOnly($event)"
                        suffix="in"></v-text-field>
                </v-col>
            </v-row>
            <v-row>
                <v-col cols="4" class="pr-2">
                    <v-text-field v-model="vestFront" :rules="vestFrontRules" label="Vest - Front" @keydown="numbersOnly($event)"
                        suffix="in"></v-text-field>
                </v-col>
                <v-col cols="4" class="px-2">
                    <v-text-field v-model="vestBack" :rules="vestBackRules" label="Vest - Back" @keydown="numbersOnly($event)"
                        suffix="in"></v-text-field>
                </v-col>
                <v-col cols="4" class="pl-2">
                </v-col>
            </v-row>
        </v-form>
        <v-dialog v-model="tutorialDialog" width="640" height="360">
            <v-card>
                <v-card-text class="pa-0 ma-0">
                    <iframe :src="tutorialVideo" width="640" height="360" frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>
                </v-card-text>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { _st } from '@/softech';
export default {
    props: {
        measurements        : { type: [Object, Array], default() { return {}; } },
        measuresRequired    : { type: Boolean, default: true }
    },
    data() {
        return {
            valid: false,
            heightFeet: '',
            heightFeetRules: [
                v => !!v || 'required',
                v => /[3-7]{1}/g.test(v) || 'Feet must be valid'
            ],
            heightFeetError: '',
            heightInch: '',
            heightInchesRules: [
                v => !!v || 'required',
                v => v >= 0 && v <= 11 || 'Inches must be valid'
            ],
            heightInchError: '',
            weight: '',
            weightRules: [
                v => !!v || 'required',
                v => this.tryParseInt(v, false) || 'Weight must be valid'
            ],
            weightError: '',
            neck: '',
            neckRules: [
                v => !!v || 'Neck is required',
                v => this.tryParseInt(v, false) || 'Neck must be valid'
            ],
            shoulder: '',
            shoulderRules: [
                v => !!v || 'Shoulders is required',
                v => this.tryParseInt(v, false) || 'Shoulders must be valid'
            ],
            coatSleeveLeft: '',
            coatSleeveRight: '',
            coatSleeveRules: [
                v => !!v || 'Coat Sleeve is required',
                v => this.tryParseInt(v, false) || 'Coat Sleeve must be valid'
            ],
            sleeveLeft: '',
            sleeveRight: '',
            sleeveRules: [
                v => !!v || 'Sleeve Length is required',
                v => this.tryParseInt(v, false) || 'Sleeve Length must be valid'
            ],
            bicep: '',
            bicepRules: [
                v => !!v || 'Bicep is required',
                v => this.tryParseInt(v, false) || 'Bicep must be valid'
            ],
            wrist: '',
            wristRules: [
                v => !!v || 'Wrist is required',
                v => this.tryParseInt(v, false) || 'Wrist must be valid'
            ],
            chest: '',
            chestRules: [
                v => !!v || 'Chest is required',
                v => this.tryParseInt(v, false) || 'Chest must be valid'
            ],
            stomach: '',
            stomachRules: [
                v => !!v || 'Stomach is required',
                v => this.tryParseInt(v, false) || 'Stomach must be valid'
            ],
            jacketFront: '',
            jacketFrontRules: [
                v => !!v || 'Jacket Length - Front is required',
                v => this.tryParseInt(v, false) || 'Jacket Length - Front must be valid'
            ],
            jacketBack: '',
            jacketBackRules: [
                v => !!v || 'Jacket Length - Back is required',
                v => this.tryParseInt(v, false) || 'Jacket Length - Back must be valid'
            ],
            waist: '',
            waistRules: [
                v => !!v || 'Waist Length is required',
                v => this.tryParseInt(v, false) || 'Waist Length must be valid'
            ],
            hips: '',
            hipsRules: [
                v => !!v || 'Hips is required',
                v => this.tryParseInt(v, false) || 'Hips must be valid'
            ],
            thigh: '',
            thighRules: [
                v => !!v || 'Thigh is required',
                v => this.tryParseInt(v, false) || 'Thigh must be valid'
            ],
            crotch: '',
            crotchRules: [
                v => !!v || 'Crotch is required',
                v => this.tryParseInt(v, false) || 'Crotch must be valid'
            ],
            knee: '',
            kneeRules: [
                v => !!v || 'Knee is required',
                v => this.tryParseInt(v, false) || 'Knee must be valid'
            ],
            leg: '',
            legRules: [
                v => !!v || 'Leg is required',
                v => this.tryParseInt(v, false) || 'Leg must be valid'
            ],
            watchHand: '',
            rep: '',
            repRules: [
                v => !!v || 'The stylist code is required'
            ],
            referral: '',
            vestFront: '',
            vestFrontRules: [
                v => !!v || 'Vest - Front is required',
                v => this.tryParseInt(v, false) || 'Vest - Front must be valid'
            ],
            vestBack: '',
            vestBackRules: [
                v => !!v || 'Vest - Back is required',
                v => this.tryParseInt(v, false) || 'Vest - Back must be valid'
            ],
            calf: '',
            cuff: '',
            cuffRules: [
                v => !!v || 'Cuff is required',
                v => this.tryParseInt(v, false) || 'Cuff must be valid'
            ],
            watchHandOptions: [
                { id: 'left', name: 'Left' },
                { id: 'right', name: 'Right' },
                { id: 'none', name: 'None' }
            ],
            tutorialDialog: false,
            tutorialVideo: '',
        }
    },
    watch: {
        measurements(v) {
            if ( _st.isNU( v ) )
                return;

            this.heightFeet = v.heightFeet || '';
            this.heightInch = v.heightInch || '';
            this.weight = v.weight || '';
            this.watchHand = v.watchHand || '';
            this.neck = v.neck || '';
            this.sleeveLeft = v.sleeveLengthLeft || '';
            this.sleeveRight = v.sleeveLengthRight || '';
            this.bicep = v.bicep || '';
            this.wrist = v.wrist || '';
            this.chest = v.chest || '';
            this.waist = v.waist || '';
            this.stomach = v.stomach || '';
            this.hips = v.hips || '';
            this.leg = v.legLength || '';
            this.thigh = v.thigh || '';
            this.knee = v.knee || '';
            this.calf = v.calf || '';
            this.cuff = v.cuff || '';
            this.crotch = v.crotch || '';
            this.shoulder = v.shoulder || '';
            this.coatSleeveLeft = v.coatSleeveLeft || '';
            this.coatSleeveRight = v.coatSleeveRight || '';
            this.jacketFront = v.jacketLengthFront || '';
            this.jacketBack = v.jacketLengthBack || '';
            this.vestFront = v.vestLengthFront || '';
            this.vestBack = v.vestLengthBack || '';
        },
    },
    mounted() {
        
    },
    computed: {
        newMeasurements() {
            if( !this.isValid() )
                return null;

            return {
                heightFeet: this.heightFeet,
                heightInch: this.heightInch,
                weight: this.weight,
                watchHand: this.watchHand,
                neck: this.neck,
                sleeveLengthLeft: this.sleeveLeft,
                sleeveLengthRight: this.sleeveRight,
                bicep: this.bicep,
                wrist: this.wrist,
                chest: this.chest,
                waist: this.waist,
                stomach: this.stomach,
                hips: this.hips,
                legLength: this.leg,
                thigh: this.thigh,
                knee: this.knee,
                calf: this.calf,
                cuff: this.cuff,
                crotch: this.crotch,
                shoulder: this.shoulder,
                coatSleeveLeft: this.coatSleeveLeft,
                coatSleeveRight: this.coatSleeveRight,
                jacketLengthFront: this.jacketFront,
                jacketLengthBack: this.jacketBack,
                vestLengthFront: this.vestFront,
                vestLengthBack: this.vestBack,
            }
        }
    },
    methods: {
        showVideo(type) {
            this.tutorialVideo = 'https://player.vimeo.com/video/';
            switch(type) {
                case 'neck': this.tutorialVideo += '310569916'; break;
                case 'sleeveLength': this.tutorialVideo += '295827766'; break;
                case 'bicep': this.tutorialVideo += '295823632'; break;
                case 'wrist': this.tutorialVideo += '295828863'; break;
                case 'chest': this.tutorialVideo += '295824352'; break;
                case 'waist': this.tutorialVideo += '296455945'; break;
                case 'stomach': this.tutorialVideo += '296740024'; break;
                case 'hips': this.tutorialVideo += '296456301'; break;
                case 'legLength': this.tutorialVideo += '295826571'; break;
                case 'thigh': this.tutorialVideo += '295828289'; break;
                case 'knee': this.tutorialVideo += '295826023'; break;
                case 'cuff': this.tutorialVideo += '295825605'; break;
                case 'crotch': this.tutorialVideo += '295825029'; break;
                case 'shoulder': this.tutorialVideo += '295827483'; break;
                case 'jacketLength': this.tutorialVideo += '297733866'; break;
            }
            
            if (this.tutorialVideo.length > 0) {
                this.tutorialVideo += '?autoplay=1';
                this.tutorialDialog = true;
            }
        },
        tryParseInt(str, defaultValue) {
            var retValue = defaultValue;
            if(str !== null) {
                if(str.length > 0) {
                    if (!isNaN(str)) {
                        // retValue = parseInt(str);
                        retValue = true;
                    }
                }
            }
            return retValue;
        },
        isValid() {
            return this.validate(this.measuresRequired);
        },
        validate(areRequired) {
            let errorFound = false;
            let showMessage = this.measuresRequired || this.heightFeet.length > 0 || this.heightInch.length > 0 || this.weight.length > 0;
            let required = areRequired || this.heightFeet.length > 0 || this.heightInch.length > 0 || this.weight.length > 0;
            this.heightFeetError = this.heightInchError = this.weightError = '';
            
            if ( required && !/[2-8]{1}/g.test(this.heightFeet) ) {
                this.heightFeetError = showMessage ? 'required' : '';
                errorFound = true;
            }

            if ( required && this.heightInch.length < 1) {
                this.heightInchError = showMessage ? 'required' : '';
                errorFound = true;
            }
            else if ( required && ( this.heightInch < 0 || this.heightInch > 11 ) ) {
                this.heightInchError = showMessage ? 'invalid' : '';
                errorFound = true;
            }

            if ( required && !this.tryParseInt(this.weight, false) ) {
                this.weightError = showMessage ? 'required' : '';
                errorFound = true;
            }

            return !errorFound;
        },
        prepareMeasurements() {
            let m = {
                heightFeet: this.heightFeet,
                heightInch: this.heightInch,
                weight: this.weight,
                watchHand: this.watchHand,
                neck: this.neck,
                sleeveLengthLeft: this.sleeveLeft,
                sleeveLengthRight: this.sleeveRight,
                bicep: this.bicep,
                wrist: this.wrist,
                chest: this.chest,
                waist: this.waist,
                stomach: this.stomach,
                hips: this.hips,
                legLength: this.leg,
                thigh: this.thigh,
                knee: this.knee,
                calf: this.calf,
                cuff: this.cuff,
                crotch: this.crotch,
                shoulder: this.shoulder,
                coatSleeveLeft: this.coatSleeveLeft,
                coatSleeveRight: this.coatSleeveRight,
                jacketLengthFront: this.jacketFront,
                jacketLengthBack: this.jacketBack,
                vestLengthFront: this.vestFront,
                vestLengthBack: this.vestBack,
            };

            this.$emit('onMeasurementsReady', m);
        },
        resetMeasurements() {
            this.heightFeet = '';
            this.heightInch = '';
            this.weight = '';
            this.watchHand = '';
            this.neck = '';
            this.sleeveLeft = '';
            this.sleeveRight = '';
            this.bicep = '';
            this.wrist = '';
            this.chest = '';
            this.waist = '';
            this.stomach = '';
            this.hips = '';
            this.leg = '';
            this.thigh = '';
            this.knee = '';
            this.calf = '';
            this.cuff = '';
            this.crotch = '';
            this.shoulder = '';
            this.coatSleeveLeft = '';
            this.coatSleeveRight = '';
            this.jacketFront = '';
            this.jacketBack = '';
            this.vestFront = '';
            this.vestBack = '';
            this.$emit('onMeasurementsCleaned');
        },
        save(customerId) {
            if( _st.isNUE( customerId ) )
                return false;

            if( !this.validate(true) )
                return false;
            
            let axiosOptions = {
                method: 'POST',
                url: '/customer/measurement',
                data: {
                    identifier: customerId,
                    measurements: {
                        height_feet: this.newMeasurements.heightFeet,
                        height_inch: this.newMeasurements.heightInch,
                        weight: this.newMeasurements.weight,
                        neck: this.newMeasurements.neck,
                        shoulder: this.newMeasurements.shoulder,
                        sleeve_length_left: this.newMeasurements.sleeveLengthLeft,
                        sleeve_length_right: this.newMeasurements.sleeveLengthRight,
                        bicep: this.newMeasurements.bicep,
                        waist: this.newMeasurements.waist,
                        wrist: this.newMeasurements.wrist,
                        chest: this.newMeasurements.chest,
                        stomach: this.newMeasurements.stomach,
                        jacket_length_front: this.newMeasurements.jacketLengthFront,
                        jacket_length_back: this.newMeasurements.jacketLengthBack,
                        hips: this.newMeasurements.hips,
                        thigh: this.newMeasurements.thigh,
                        crotch: this.newMeasurements.crotch,
                        knee: this.newMeasurements.knee,
                        leg_length: this.newMeasurements.legLength,
                        vest_length_front: this.newMeasurements.vestLengthFront,
                        vest_length_back: this.newMeasurements.vestLengthBack,
                        cuff: this.newMeasurements.cuff,
                        watch_hand: this.newMeasurements.watchHand,
                        calf: this.newMeasurements.calf,
                        coat_sleeve_left: this.newMeasurements.coatSleeveLeft,
                        coat_sleeve_right: this.newMeasurements.coatSleeveRight
                    }
                },
                config: { headers: {'Content-Type': 'application/x-www-form-urlencoded' } }
            };

            this.$http(axiosOptions)
            .then( response => { 
                if( response.status === 401 ) {
                    let obj = this;
                    this.$root.$el.__vue__.$once('login', function() {
                        obj.save(customerId);
                    });
                    return;
                }

                this.saving = false;
                if(!response.data.status && response.data.error) {
                    this.$emit('error', response.data.message);
                    return false;
                }

                if(!response.data.status && !response.data.error) 
                    this.$emit('error', 'Error saving');

                if( response.data.status )
                    this.$emit('saved', this.newMeasurements);
            })
            .catch( error => {
                this.$emit('error', error);
                console.log(error);
            });
        },
        load(customerId) {
            if( _st.isNUE( customerId ) )
                return false;
            
            this.$http
            .get( '/customer/measurements/' + customerId ) 
            .then( response => { 
                if( response.status === 401 ) {
                    let obj = this;
                    this.$root.$el.__vue__.$once('login', function() {
                        obj.load(customerId);
                    });
                    return;
                }
                
                if(!response.data.status && response.data.error) {
                    this.$emit('error', response.data.message);
                    return false;
                }

                if(!response.data.status && !response.data.error) 
                    this.$emit('error', 'Error loading');

                this.heightFeet = response.data.data.heightFeet != null ? response.data.data.heightFeet : '';
                this.heightInch = response.data.data.heightInch != null ? response.data.data.heightInch : '';
                this.weight = response.data.data.weight != null ? response.data.data.weight : '';
                this.watchHand = response.data.data.watchHand != null ? response.data.data.watchHand : '';
                this.neck = response.data.data.neck != null ? response.data.data.neck : '';
                this.sleeveLeft = response.data.data.sleeveLengthLeft != null ? response.data.data.sleeveLengthLeft : '';
                this.sleeveRight = response.data.data.sleeveLengthRight != null ? response.data.data.sleeveLengthRight : '';
                this.bicep = response.data.data.bicep != null ? response.data.data.bicep : '';
                this.wrist = response.data.data.wrist != null ? response.data.data.wrist : '';
                this.chest = response.data.data.chest != null ? response.data.data.chest : '';
                this.waist = response.data.data.waist != null ? response.data.data.waist : '';
                this.stomach = response.data.data.stomach != null ? response.data.data.stomach : '';
                this.hips = response.data.data.hips != null ? response.data.data.hips : '';
                this.leg = response.data.data.legLength != null ? response.data.data.legLength : '';
                this.thigh = response.data.data.thigh != null ? response.data.data.thigh : '';
                this.knee = response.data.data.knee != null ? response.data.data.knee : '';
                this.calf = response.data.data.calf != null ? response.data.data.calf : '';
                this.cuff = response.data.data.cuff != null ? response.data.data.cuff : '';
                this.crotch = response.data.data.crotch != null ? response.data.data.crotch : '';
                this.shoulder = response.data.data.shoulder != null ? response.data.data.shoulder : '';
                this.coatSleeveLeft = response.data.data.coatSleeveLeft != null ? response.data.data.coatSleeveLeft : '';
                this.coatSleeveRight = response.data.data.coatSleeveRight != null ? response.data.data.coatSleeveRight : '';
                this.jacketFront = response.data.data.jacketLengthFront != null ? response.data.data.jacketLengthFront : '';
                this.jacketBack = response.data.data.jacketLengthBack != null ? response.data.data.jacketLengthBack : '';
                this.vestFront = response.data.data.vestLengthFront != null ? response.data.data.vestLengthFront : '';
                this.vestBack = response.data.data.vestLengthBack != null ? response.data.data.vestLengthBack : '';
            })
            .catch(function (error) {
                this.$emit('error', error);
                console.log(error);
            });
        }
    },
}
</script>

<style>

</style>
